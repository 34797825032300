import React, { useEffect } from "react";
import SidebarComponent from "../../common/sidebar";
import Footer from "../../common/footer";
import { useTranslation } from "react-i18next";

const TermsOfUse = ({ openSidebar, setOpenSidebar }) => {
  useEffect(() => {
    document.body.style.overflow = openSidebar ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [openSidebar]);

  const { t } = useTranslation();

  return (
    <div className="w-full h-auto relative overflow-hidden">
      <SidebarComponent
        openSidebar={openSidebar}
        setOpenSidebar={setOpenSidebar}
      />
      <div
        className={`${openSidebar ? "bg-black-00000080 opacity-50" : ""}`}
        onClick={() => {
          if (openSidebar) setOpenSidebar(false);
        }}
      >
        <div className="bg-small-stars">
          <div className="text-white relative z-1 text-ft12 medium:px-52 mobile:px-10 px-20 tab:px-20 pt-20 mobile:pt-5 pb-20 mobile:pb-16">
            <div className="3xl:px-44 pb-44 mobile:pb-0">
              <div className="font-AspireSCBlackRegular text-ft55 mobile:text-ft24 tab:text-ft35-59">
                {t("astromint_fe.termsOfUse.termsTitle")}
              </div>
              <div className="text-ft15-24 font-SourceSansProRegular font-normal mt-14 mobile:mt-5 mobile:text-ft3">
                <p>{t("astromint_fe.termsOfUse.description")}</p>
                <br />
                {/* <p>{t("astromint_fe.privacy.types")}</p> */}
                {/* <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.privacy.nonPersonal")}</li>
                </ul> */}
                <p> &nbsp; &nbsp; {t("astromint_fe.termsOfUse.termsDes_1")}</p>
                <br />
                <p> &nbsp; &nbsp; {t("astromint_fe.termsOfUse.termsDes_2")}</p>
                <br />
                <p> &nbsp; &nbsp; {t("astromint_fe.termsOfUse.termsDes_3")}</p>
                <br />
                <p> &nbsp; &nbsp; {t("astromint_fe.termsOfUse.termsDes_4")}</p>
                <br />
                <p> &nbsp; &nbsp; {t("astromint_fe.termsOfUse.accept")}</p>
                <br />
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.termsOfUse.submissions")}</li>
                </ul>
                <p>{t("astromint_fe.termsOfUse.submissionsDes")}</p>
                <br />
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.termsOfUse.prohibitedActivities")}</li>
                </ul>
                <p>{t("astromint_fe.termsOfUse.prohibitedActivitiesDes")}</p>
                <ul className="list-disc alphabetic-list pl-5">
                  <li className="pl-3">{t("astromint_fe.termsOfUse.a")}</li>
                  <li className="pl-3">{t("astromint_fe.termsOfUse.b")}</li>
                  <li className="pl-3">{t("astromint_fe.termsOfUse.c")}</li>
                  <li className="pl-3">{t("astromint_fe.termsOfUse.d")}</li>
                  <li className="pl-3">{t("astromint_fe.termsOfUse.e")}</li>
                  <li className="pl-3">{t("astromint_fe.termsOfUse.f")}</li>
                  <li className="pl-3">{t("astromint_fe.termsOfUse.g")}</li>
                  <li className="pl-3">{t("astromint_fe.termsOfUse.h")}</li>
                  <li className="pl-3">{t("astromint_fe.termsOfUse.i")}</li>
                  <li className="pl-3">{t("astromint_fe.termsOfUse.j")}</li>
                  <li className="pl-3">{t("astromint_fe.termsOfUse.k")}</li>
                  <li className="pl-3">{t("astromint_fe.termsOfUse.l")}</li>
                  <li className="pl-3">{t("astromint_fe.termsOfUse.m")}</li>
                  <li className="pl-3">{t("astromint_fe.termsOfUse.n")}</li>
                  <li className="pl-3">{t("astromint_fe.termsOfUse.o")}</li>
                  <li className="pl-3">{t("astromint_fe.termsOfUse.p")}</li>
                </ul>
                <br />
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.termsOfUse.intellectualPropertyRights")}</li>
                </ul>
                <p>&nbsp; &nbsp;{t("astromint_fe.termsOfUse.intellectualDes")}</p>
                <p>&nbsp; &nbsp;{t("astromint_fe.termsOfUse.intellectualDes1")}</p>
                <br/>
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.termsOfUse.thirdPartyWebsitesandContent")}</li>
                </ul>
                <p>&nbsp; &nbsp;{t("astromint_fe.termsOfUse.thirdPartyDes")}</p>
                <br/>
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.termsOfUse.site")}</li>
                </ul>
                <p>{t("astromint_fe.termsOfUse.siteDes")}</p>
                <ul className="list-disc alphabetic-list pl-5">
                  <li className="pl-3">{t("astromint_fe.termsOfUse.site_a")}</li>
                  <li className="pl-3">{t("astromint_fe.termsOfUse.site_b")}</li>
                  <li className="pl-3">{t("astromint_fe.termsOfUse.site_c")}</li>
                  <li className="pl-3">{t("astromint_fe.termsOfUse.site_d")}</li>
                  <li className="pl-3">{t("astromint_fe.termsOfUse.site_e")}</li>
                </ul>
                <br/>
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.termsOfUse.privacy")}</li>
                </ul>
                <p>&nbsp; &nbsp;{t("astromint_fe.termsOfUse.privacyDes")}<a className="text-blue-50" target="_blank" rel="noreferrer" href="https://www.algogard.com/privacy-policy.html">{t("astromint_fe.termsOfUse.privacy_website")}</a>{t("astromint_fe.termsOfUse.privacyDes2")}</p>
                <br/>
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.termsOfUse.term")}</li>
                </ul>
                <p>&nbsp; &nbsp;{t("astromint_fe.termsOfUse.termDes")}</p>
                <br/>
                <p>&nbsp; &nbsp;{t("astromint_fe.termsOfUse.reserves")}</p>
                <br/>
                <p>&nbsp; &nbsp;{t("astromint_fe.termsOfUse.protect")}</p>
                <br/>
                <p>&nbsp; &nbsp;{t("astromint_fe.termsOfUse.protect2")}</p>
                <br/>
                <p>&nbsp; &nbsp;{t("astromint_fe.termsOfUse.youUnderstandCaps")}</p>
                <br/>
                <p>&nbsp; &nbsp;{t("astromint_fe.termsOfUse.terminates")}</p>
                <br/>
                <p>{t("astromint_fe.termsOfUse.modifications")}</p>
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.termsOfUse.toAgreement")}: &nbsp; &nbsp; {t("astromint_fe.termsOfUse.agreementDes")}</li>
                  <li>{t("astromint_fe.termsOfUse.toServices")}: &nbsp; &nbsp; {t("astromint_fe.termsOfUse.serviceDes")}</li>
                </ul>
                <br/>
                <p>{t("astromint_fe.termsOfUse.disputes")}</p>
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.termsOfUse.betweenUsers")}: &nbsp; &nbsp; {t("astromint_fe.termsOfUse.betweenDes")}</li><br/>
                  <li>{t("astromint_fe.termsOfUse.withCompany")}: &nbsp; &nbsp; </li>
                </ul>
                <ul className="list-disc alphabetic-list pl-5">
                  <li className="pl-3">{t("astromint_fe.termsOfUse.a_governing")}: &nbsp; &nbsp;{t("astromint_fe.termsOfUse.governingLaw")}</li><br/>
                  <li className="pl-3">{t("astromint_fe.termsOfUse.b_informalResolution")}: &nbsp; &nbsp;{t("astromint_fe.termsOfUse.informalResolution")}</li><br/>
                  <li className="pl-3">{t("astromint_fe.termsOfUse.c_arbitration")}: &nbsp; &nbsp;{t("astromint_fe.termsOfUse.arbitration")}</li><br/>
                  <li className="pl-3">{t("astromint_fe.termsOfUse.d_Restrictions")}: &nbsp; &nbsp;{t("astromint_fe.termsOfUse.restrictions")}</li><br/>
                  <li className="pl-3">{t("astromint_fe.termsOfUse.e_Exceptions")}: &nbsp; &nbsp;{t("astromint_fe.termsOfUse.exceptionsDes")}</li><br/>
                </ul>
                <br/>
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.termsOfUse.corrections")}</li>
                </ul>
                <p>&nbsp; &nbsp;{t("astromint_fe.termsOfUse.correctionsDes")}</p>
                
                <br/>
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.termsOfUse.disclaimers")}</li>
                </ul>
                <p>&nbsp; &nbsp;{t("astromint_fe.termsOfUse.disclaimersDes")}</p>
                <br/>
                <p>&nbsp; &nbsp;{t("astromint_fe.termsOfUse.aggreCaps")}</p>
                <br/>
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.termsOfUse.LimitationsAndLiability")}</li>
                </ul>
                <p>&nbsp; &nbsp;{t("astromint_fe.termsOfUse.limitationDesCaps")}</p>
                <br/>
                <p>&nbsp; &nbsp;{t("astromint_fe.termsOfUse.limitationDesCaps1")}</p>
                <br/>
                <p>&nbsp; &nbsp;{t("astromint_fe.termsOfUse.limitationDesCaps2")}</p>
                <br/>
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.termsOfUse.Indemnity")}</li>
                </ul>
                <p>&nbsp; &nbsp;{t("astromint_fe.termsOfUse.indemnityDes")}</p>
                <br/>
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.termsOfUse.notices")}</li>
                </ul>
                <p>&nbsp; &nbsp;{t("astromint_fe.termsOfUse.noticeDes")}</p>
                <br/>
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.termsOfUse.userData")}</li>
                </ul>
                <p>&nbsp; &nbsp;{t("astromint_fe.termsOfUse.userDataDes")}</p>
                <br/>
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.termsOfUse.electronicContracting")}</li>
                </ul>
                <p>&nbsp; &nbsp;{t("astromint_fe.termsOfUse.electronicContractingDes")}</p>
                <br/>
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.termsOfUse.miscellaneous")}</li>
                </ul>
                <p>&nbsp; &nbsp;{t("astromint_fe.termsOfUse.miscellaneousDes")}</p>
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.termsOfUse.contact")}</li>
                </ul>
                <p>&nbsp; &nbsp;{t("astromint_fe.termsOfUse.contactDes")}</p>
                <p>&nbsp; &nbsp;{t("astromint_fe.termsOfUse.contactDes2")}</p>
                <p>&nbsp; &nbsp;{t("astromint_fe.termsOfUse.attn")}</p>
                <p>&nbsp; &nbsp;{t("astromint_fe.termsOfUse.city")}</p>
                <a className="text-blue-50" href="mailto:hello@algogard.com">&nbsp; &nbsp;{t("astromint_fe.termsOfUse.email")}</a>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

const TermsOfUseComponent = React.memo(TermsOfUse);

export default TermsOfUseComponent;
