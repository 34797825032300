import React from "react";
import { Router, Route } from "react-router-dom";
import { Redirect, Switch } from "react-router";
import { connect } from "react-redux";
import { history } from "./managers/history";
import LandingPage from "./modules/landingPage";
import Privacy from "./modules/privacy";
import Terms from "./modules/terms";

const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path={"/"} component={LandingPage} />
        <Route exact path={"/privacy-policy"} component={Privacy} />
        <Route exact path={"/terms-of-use"} component={Terms} />
        <Redirect exact from="*" to="/" />
      </Switch>
    </Router>
  );
};

const mapStateToProps = (state) => {
  return { user: state.user };
};
export default connect(mapStateToProps)(Routes);
