import React, { useState, useEffect } from "react";
import SidebarComponent from "../../common/sidebar";
import TitleComponent from "./title";
import HowItWorksComponent from "./howItWorks";
import DescriptionComponent from "./description";
import ContributersComponent from "./contributers";
import PartnershipsComponent from "./partnerships";
import Footer from "../../common/footer";
import HeaderComponent from "../../common/header";
import SocialMediaPanel from "./socialMediaPanel";
import TwinklingStar from "./twinklingStar";
import EarnRewardsComponent from "./earnRewards";

function LandingPage() {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [header, setHeader] = useState("transparent");

  useEffect(() => {
    document.body.style.overflow = openSidebar ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [openSidebar]);

  const listenScrollEvent = () => {
    if (window.scrollY < 33) {
      return setHeader("transparent");
    } else if (window.scrollY > 30) {
      return setHeader("blur");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", listenScrollEvent);
    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  return (
    <div className="bg-blue-0F161E bg-no-repeat mobile:bg-left-top-250 bg-big-stars">
      <TwinklingStar />
      <div className="bg-clouds mobile:bg-cloudy bg-no-repeat tab:bg-contain desktop:bg-contain mobile:bg-center-70 ">
        <div className="bg-small-stars">
          <HeaderComponent
            openSidebar={openSidebar}
            setOpenSidebar={setOpenSidebar}
            header={header}
          />
          <div className="w-full h-auto relative overflow-hidden">
            <SidebarComponent
              openSidebar={openSidebar}
              setOpenSidebar={setOpenSidebar}
            />
            <div
              className={`${openSidebar ? "bg-black-00000080 opacity-50" : ""}`}
              onClick={() => {
                if (openSidebar) setOpenSidebar(false);
              }}
            >
              <TitleComponent />
              <HowItWorksComponent />
              <DescriptionComponent />
              <EarnRewardsComponent />
              <ContributersComponent />
              <div className="bg-orbit bg-no-repeat bg-contain">
                <div className="bg-small-stars bg-no-repeat">
                  <PartnershipsComponent />
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
        <SocialMediaPanel />
      </div>
    </div>
  );
}

export default LandingPage;
