import React, { useEffect } from "react";
import SidebarComponent from "../../common/sidebar";
import Footer from "../../common/footer";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = ({ openSidebar, setOpenSidebar }) => {
  useEffect(() => {
    document.body.style.overflow = openSidebar ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [openSidebar]);

  const { t } = useTranslation();

  return (
    <div className="w-full h-auto relative overflow-hidden">
      <SidebarComponent
        openSidebar={openSidebar}
        setOpenSidebar={setOpenSidebar}
      />
      <div
        className={`${openSidebar ? "bg-black-00000080 opacity-50" : ""}`}
        onClick={() => {
          if (openSidebar) setOpenSidebar(false);
        }}
      >
        <div className="bg-small-stars">
          <div className="text-white relative z-1 text-ft12 medium:px-52 mobile:px-10 px-20 tab:px-20 pt-20 mobile:pt-5 pb-20 mobile:pb-16">
            <div className="3xl:px-44 pb-44 mobile:pb-0">
              <div className="font-AspireSCBlackRegular text-ft55 mobile:text-ft24 tab:text-ft35-59">
                {t("astromint_fe.title.privacyPolicy")}
              </div>
              <div className="text-ft15-24 font-SourceSansProRegular font-normal mt-14 mobile:mt-5 mobile:text-ft3">
                <p>{t("astromint_fe.privacy.description")}</p>
                <br />
                <p>{t("astromint_fe.privacy.general")}</p>
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.privacy.generalDescription-1")}</li>
                  <li>{t("astromint_fe.privacy.generalDescription-2")}</li>
                </ul>
                <p>{t("astromint_fe.privacy.generalPoint-1")}</p>
                <p>{t("astromint_fe.privacy.generalPoint-2")}</p>
                <br />
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.privacy.nonPersonal")}</li>
                </ul>
                <p>{t("astromint_fe.privacy.nonPersonalDescription")}</p>
                <br />
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.privacy.cookies")}</li>
                </ul>
                {t("astromint_fe.privacy.cookiesDescription")}
                <br/>
                {t("astromint_fe.privacy.cookiesDescription-2")}
                <br />
                <br />
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.privacy.beacons")}</li>
                </ul>
                {t("astromint_fe.privacy.beaconsDescription")}
                <br />
                <br />
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.privacy.analytics")}</li>
                </ul>
                {t("astromint_fe.privacy.analyticsDescription")}
                <br />
                <br />
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.privacy.aggregated")}</li>
                </ul>
                {t("astromint_fe.privacy.aggregatedDescription")}
                <br />
                <br />
                <br />
                {t("astromint_fe.privacy.mobileDevice")}
                
                <br />
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.privacy.mobileDeviceTitle")}</li>
                </ul>
                {t("astromint_fe.privacy.mobileDevicePoint")}
                <br />
                <br />
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.privacy.geoLocation")}</li>
                </ul>
                {t("astromint_fe.privacy.geoLocationPoint")}
                <br />
                <br />
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.privacy.pushNotification")}</li>
                </ul>
                {t("astromint_fe.privacy.pushNotificationPoint")}
                <br />
                <br />
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.privacy.mobileAnalytics")}</li>
                </ul>
                {t("astromint_fe.privacy.mobileAnalyticsPoint")}
                <br />
                <br />
                <br />
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.privacy.socialMedia")}</li>
                </ul>
                {t("astromint_fe.privacy.socialMediaDescription-1")}
                <br />
                <br />
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.privacy.collection")}</li>
                </ul>
                {t("astromint_fe.privacy.collectionDescription-2")}
                <br />
                <br />
                {t("astromint_fe.privacy.section")}
                <br />
                <br />
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.privacy.companyCommunications")}</li>
                </ul>
                {t("astromint_fe.privacy.companyDescription")}
                <br />
                <br />
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.privacy.companyDisclosures")}</li>
                </ul>
                {t("astromint_fe.privacy.companyDisclosuresDescription")}
                <br />
                <br />
                <ul className="list-disc pl-7">
                  <li>{t("astromint_fe.privacy.disclosurePoint-1")}{" : "}{t("astromint_fe.privacy.disclosureDescreption-1")}</li><br/>
                  <li>{t("astromint_fe.privacy.disclosurePoint-2")}{" : "}{t("astromint_fe.privacy.disclosureDescreption-2")}</li><br/>
                  <li>{t("astromint_fe.privacy.disclosurePoint-3")}{" : "}{t("astromint_fe.privacy.disclosureDescreption-3")}</li>
                </ul>
                <br />
                <br />
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.privacy.changePersonally")}</li>
                </ul>
                {t("astromint_fe.privacy.changePersonallyDescreption")}
                <br />
                <br />
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.privacy.generalUse")}</li>
                </ul>
                {t("astromint_fe.privacy.generalUseDescreption")}
                <br />
                <br />
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.privacy.thirdParty")}</li>
                </ul>
                {t("astromint_fe.privacy.thirdPartyDescription")}
                <br />
                {t("astromint_fe.privacy.security")}
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.privacy.securityDescription-1")}</li>
                  <li>{t("astromint_fe.privacy.securityDescription-2")}</li>
                </ul>
                <br />
                {t("astromint_fe.privacy.privacyPolicyChanges")}
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.privacy.privacyPolicyChangesDescription")}</li>
                </ul>
                <br />
                {t("astromint_fe.privacy.children")}
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.privacy.childrenDescription")}</li>
                </ul>
                <br />
                {t("astromint_fe.privacy.trackPolicy")}
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.privacy.trackPolicyDescription")}</li>
                </ul>
                <br />
                {t("astromint_fe.privacy.contact")}
                <ul className="list-disc pl-5">
                  <li>{t("astromint_fe.privacy.contactDes")}</li>
                </ul>
                <br/>
                <div className="pl-5">{t("astromint_fe.privacy.name")}</div>
                <div className="pl-5">{t("astromint_fe.privacy.attn")}</div>
                <div className="pl-5">{t("astromint_fe.privacy.address")}</div>
                <a className="pl-5 text-blue-50 " href="mailto:hello@astromint.xyz" >{t("astromint_fe.privacy.email")}</a>
                
                
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

const PrivacyPolicyComponent = React.memo(PrivacyPolicy);

export default PrivacyPolicyComponent;
