import React from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import ProfileComponent from "../../common/profile";
import { comingSoonPartners } from "../../constants";

const visible = { opacity: 1, x: 0, transition: { duration: 0.5 } };

const itemVariants = {
  hidden: { opacity: 0, x: 90 },
  visible,
};

const EarnRewardsComponent = () => {
  const { t } = useTranslation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <>
      <div className="text-white relative z-3 ">
        <img
          className="absolute -top-125 -right-91 mobile:hidden mobile:-top-20 tab:-right-100"
          src="/images/red-planet-bg-gradient.svg"
          alt="Red planet"
        />
        <img
          className="absolute -top-125 right-0 mobile:-top-20 tab:hidden desktop:hidden"
          src="/images/red-planet-small.svg"
          alt="Red planet"
        />
        <div className="relative mx-auto medium:px-52 mobile:px-10 tab:px-20 px-20 pt-16 mobile:pt-0 pb-28">
          <div className="4xl:px-44">
            <div className="font-AspireSCBlackRegular text-ft45 mobile:text-ft8 tab:text-ft28 small:text-ft4 desktop:pl-12 pl-5 max-w-205">
              {t("astromint_fe.title.earnRewards")}
            </div>
            <motion.div
              ref={ref}
              variants={itemVariants}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              className="bg-darkBlue-25 w-fit backdrop-blur-4xl desktop:pl-12 desktop:pr-12 pl-5 pr-5 mobile:pr-5 desktop:pb-9 desktop:pt-7 pt-4 pb-4 rounded-7.5 mt-2 tab:mr-32 lg:mr-40 4xl:mr-0 description-bg cursor-default"
            >
              <div
                ref={ref}
                variants={itemVariants}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                className="text-ft12 font-SourceSansVariableRoman mobile:text-ft3 mobile:w-64 tab:text-ft5"
              >
                {t("astromint_fe.description.earnRewardsDescription")}
              </div>
            </motion.div>
            <div className="mt-75px mobile:mt-8">
              <span className="text-ft24-30 font-SpaceGrotesk font-bold mobile:text-ft4">
                {t("astromint_fe.features.pendingIntegration")}
              </span>
              <div className="grid grid-cols-4 mt-7.5 tab:grid-cols-3 mobile:grid-cols-2 gap-5">
                {comingSoonPartners?.map((item, index) => (
                  <ProfileComponent
                    key={index}
                    imgSrc={item.imgSrc}
                    advisorName={item.advisorName}
                    linkedIn={item.linkedIn}
                    type="website"
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EarnRewardsComponent;
