export const httpConstants = {
  METHOD_TYPE: {
    POST: "POST",
    PUT: "PUT",
    GET: "GET",
    DELETE: "DELETE",
  },
  CONTENT_TYPE: {
    APPLICATION_JSON: "application/json",
    MULTIPART_FORM_DATA: "multipart/form-data",
    APPLICATION_FORM_URLENCODED: "application/x-www-form-urlencoded",
    IMAGE_PNG: "image/png",
  },
  DEVICE_TYPE: {
    WEB: "web",
  },
  API_END_POINT: {},
};

export const REGEX = {
  nameRegex: /^[a-zA-Z][a-zA-Z\s]*$/,
  emailRegex: /^[a-zA-Z0-9.%+-]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,}$/,
  passwordRegex:
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
};

export const validationMessages = {
  SIGNUP_ERROR: "Failed to create account",
  SIGNIN_ERROR: "Failed to login",
};

export const contributorDetails = [
  {
    imgSrc: "/images/rylie.svg",
    contributorName: "astromint_fe.features.rylie",
    role: "astromint_fe.features.coreContributor",
    linkedIn: "https://www.linkedin.com/in/rylierueda/",
  },
  {
    imgSrc: "/images/ryan.svg",
    contributorName: "astromint_fe.features.ryan",
    role: "astromint_fe.features.coreContributor",
    linkedIn: "https://www.linkedin.com/in/ryanmsoscia/",
  },
];

export const advisorDetails = [
  {
    imgSrc: "/images/david.svg",
    advisorName: "astromint_fe.features.davidGarcia",
    linkedIn: "https://www.linkedin.com/in/dglnkd/",
  },
  {
    imgSrc: "/images/arul-murugan.svg",
    advisorName: "astromint_fe.features.arulMurugan",
    linkedIn: "https://www.linkedin.com/in/arul-murugan11/",
  },
  {
    imgSrc: "/images/amit.svg",
    advisorName: "astromint_fe.features.amitMehra",
    linkedIn: "https://www.linkedin.com/in/aamehra/",
  },
  {
    imgSrc: "/images/zach.svg",
    advisorName: "astromint_fe.features.zachZukowski",
    linkedIn: "https://www.linkedin.com/in/zachzukowski/",
  },
  {
    imgSrc: "/images/tom.svg",
    advisorName: "astromint_fe.features.tomWeber",
    linkedIn: "https://www.linkedin.com/in/tom-weber-big-brain-holdings/ ",
  },
];

export const containerDetails = [
  {
    imgSrc: "/images/golden-star.svg",
    alt: "astromint_fe.features.star",
    title: "astromint_fe.features.overcollateralized",
  },
  {
    imgSrc: "/images/dollar-bucket.svg",
    alt: "astromint_fe.features.dollar",
    title: "astromint_fe.features.capitalEfficient",
  },
  {
    imgSrc: "/images/rocket.svg",
    alt: "astromint_fe.features.rocket",
    title: "astromint_fe.features.ibcReady",
  },
  {
    imgSrc: "/images/muscular-hand.svg",
    alt: "astromint_fe.features.muscularHand",
    title: "astromint_fe.features.noBorrowCost",
  },
  {
    imgSrc: "/images/search-lens.svg",
    alt: "astromint_fe.features.lens",
    title: "astromint_fe.features.redeemableCollateralDesc",
  },
  {
    imgSrc: "/images/golden-star.svg",
    alt: "astromint_fe.features.star",
    title: "astromint_fe.features.liquidityAsService",
  },
];

export const trustedSupporters = [
  {
    imgSrc: "/images/borderless.svg",
    link: "https://borderlesscapital.io",
  },
  {
    imgSrc: "/images/big-brain.svg",
    link: "https://www.bigbrain.holdings",
  },
  {
    imgSrc: "/images/master-ventures.svg",
    link: "https://masterventures.com/",
  },
  {
    imgSrc: "/images/master-ventures-invest.svg",
    link: "https://mvim.com/",
  },
  {
    imgSrc: "/images/neutron.svg",
    link: "https://www.neutron.org/",
  },
];

export const partners = [
  {
    imgSrc: "/images/neutron.svg",
    link: "https://www.neutron.org/",
  },
  {
    imgSrc: "/images/rokker.svg",
    link: "https://www.rokk3r.com/",
  },
  {
    imgSrc: "/images/pyth.svg",
    link: "https://pyth.network/",
  },
  {
    imgSrc: "/images/wormhole.svg",
    link: "https://wormhole.com/",
  },
];

export const primePartners = [
  {
    imgSrc: "/images/neutron.svg",
    link: "https://www.neutron.org/",
  },
  {
    imgSrc: "/images/rokker.svg",
    link: "https://www.rokk3r.com/",
  },
  {
    imgSrc: "/images/pyth.svg",
    link: "https://pyth.network/",
  },
  {
    imgSrc: "/images/wormhole.svg",
    link: "https://wormhole.com/",
  },
];

export const extendedPartners = [
  
  {
    imgSrc: "/images/pyth.svg",
    link: "https://pyth.network/",
  },
  {
    imgSrc: "/images/wormhole.svg",
    link: "https://wormhole.com/",
  },
];

export const comingSoonPartners = [
  {
    imgSrc: "/images/mars-protocol.svg",
    advisorName: "astromint_fe.features.partner1",
  },
  {
    imgSrc: "/images/coming-soon.svg",
    advisorName: "astromint_fe.features.partner2",
  },
  {
    imgSrc: "/images/coming-soon.svg",
    advisorName: "astromint_fe.features.partner3",
  },
];

export const socialUrls = {
  twitter: "https://twitter.com/astromintxyz",
  telegram: "https://t.me/astromintxyz",
  medium: "https://medium.com/@astromintxyz",
  whitePaperLink: "https://docsend.com/view/z25a2w2yuraexg7b",
}

export const urls =  {
  dApp: "https://app.astromint.xyz",
}