import React from "react";
import { socialUrls } from "../../constants";

const SocialMediaPanel = () => {
  return (
    <div className=" fixed bottom-52 -right-3 z-3 mobile:hidden h-53.5 w-20 rounded-bl-10 rounded-tl-10 tab:hidden panel-gradient">
      <div
        onClick={() =>
          window.open(socialUrls.twitter, "_blank")
        }
      >
        <img
          src="/images/twitter.svg"
          alt="twitter"
          className="absolute cursor-pointer top-9 left-5 h-8 w-8"
          loading="lazy"
        />
      </div>
      <div onClick={() => window.open(socialUrls.telegram, "_blank")}>
        <img
          src="/images/telegram.svg"
          alt="telegram"
          className="absolute cursor-pointer bottom-23 left-5 h-7 w-7"
          loading="lazy"
        />
      </div>
      <div onClick={() => window.open(socialUrls.medium, "_blank")}>
        <img
          src="/images/round-logo.svg"
          alt="logo"
          className="absolute cursor-pointer bottom-9 left-5 h-8 w-8"
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default SocialMediaPanel;
