import React from "react";

const TwinklingStar = () => {
  return (
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 1858 957"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute top-0"
        preserveAspectRatio="xMinYMin slice"
      >
        <circle
          cx="38.8999"
          cy="166.783"
          r="18.1533"
          fill="url(#paint0_radial_2143_3505)"
          fillOpacity="0.6"
        />
        <circle
          opacity="0.9"
          cx="38.9003"
          cy="166.783"
          r="12.9667"
          fill="url(#paint1_radial_2143_3505)"
        />
        <circle
          opacity="0.9"
          cx="38.9001"
          cy="166.783"
          r="10.7438"
          fill="url(#paint2_radial_2143_3505)"
        />
        <path
          className="twinkling"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.4083 165.873L38.2191 135.293L35.5968 165.873L0 167.387L35.8336 169.215L38.143 203.94L41.0107 169.215L72.0648 168.183L41.4083 165.873Z"
          fill="url(#paint3_radial_2143_3505)"
        />
        <circle
          cx="38.8997"
          cy="166.783"
          r="6.2981"
          fill="url(#paint4_radial_2143_3505)"
        />
        <circle
          cx="55.723"
          cy="907.688"
          r="20.4041"
          fill="url(#paint5_radial_2143_3505)"
          fillOpacity="0.6"
        />
        <circle
          opacity="0.9"
          cx="55.7228"
          cy="907.688"
          r="14.5744"
          fill="url(#paint6_radial_2143_3505)"
        />
        <circle
          opacity="0.9"
          cx="55.7234"
          cy="907.688"
          r="12.0759"
          fill="url(#paint7_radial_2143_3505)"
        />
        <path
        className="twinkling"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M58.5424 906.665L54.9579 872.293L52.0103 906.665L12 908.367L52.2766 910.42L54.8723 949.451L58.0955 910.42L93 909.26L58.5424 906.665Z"
          fill="url(#paint8_radial_2143_3505)"
        />
        <circle
          cx="55.723"
          cy="907.688"
          r="7.07899"
          fill="url(#paint9_radial_2143_3505)"
        />
        <circle
          cx="577.63"
          cy="580.688"
          r="13.4866"
          fill="url(#paint10_radial_2143_3505)"
          fillOpacity="0.6"
        />
        <circle
          opacity="0.9"
          cx="577.63"
          cy="580.688"
          r="9.63331"
          fill="url(#paint11_radial_2143_3505)"
        />
        <circle
          opacity="0.9"
          cx="577.63"
          cy="580.688"
          r="7.98188"
          fill="url(#paint12_radial_2143_3505)"
        />
        <path
        className="twinkling"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M579.494 580.012L577.125 557.293L575.176 580.012L548.73 581.137L575.352 582.494L577.068 608.293L579.198 582.494L602.269 581.727L579.494 580.012Z"
          fill="url(#paint13_radial_2143_3505)"
        />
        <circle
          cx="577.63"
          cy="580.688"
          r="4.67904"
          fill="url(#paint14_radial_2143_3505)"
        />
        <circle
          cx="1419.4"
          cy="541.569"
          r="17.4533"
          fill="url(#paint15_radial_2143_3505)"
          fillOpacity="0.6"
        />
        <circle
          opacity="0.9"
          cx="1419.4"
          cy="541.569"
          r="12.4666"
          fill="url(#paint16_radial_2143_3505)"
        />
        <circle
          opacity="0.9"
          cx="1419.4"
          cy="541.569"
          r="10.3295"
          fill="url(#paint17_radial_2143_3505)"
        />
        <path
        className="twinkling"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1421.81 540.694L1418.75 511.293L1416.22 540.694L1382 542.15L1416.45 543.906L1418.67 577.293L1421.43 543.906L1451.29 542.914L1421.81 540.694Z"
          fill="url(#paint18_radial_2143_3505)"
        />
        <circle
          cx="1419.4"
          cy="541.569"
          r="6.05522"
          fill="url(#paint19_radial_2143_3505)"
        />
        <circle
          cx="1786.37"
          cy="179.018"
          r="11.3711"
          fill="url(#paint25_radial_2143_3505)"
          fillOpacity="0.6"
        />
        <circle
          opacity="0.9"
          cx="1786.37"
          cy="179.018"
          r="8.1222"
          fill="url(#paint26_radial_2143_3505)"
        />
        <circle
          opacity="0.9"
          cx="1786.37"
          cy="179.018"
          r="6.72982"
          fill="url(#paint27_radial_2143_3505)"
        />
        <path
        className="twinkling"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1787.94 178.448L1785.94 159.293L1784.3 178.448L1762 179.396L1784.45 180.541L1785.89 202.293L1787.69 180.541L1807.14 179.895L1787.94 178.448Z"
          fill="url(#paint28_radial_2143_3505)"
        />
        <circle
          cx="1786.37"
          cy="179.018"
          r="3.94507"
          fill="url(#paint29_radial_2143_3505)"
        />
        <circle
          cx="1604.37"
          cy="616.018"
          r="11.3711"
          fill="url(#paint30_radial_2143_3505)"
          fillOpacity="0.6"
        />
        <circle
          opacity="0.9"
          cx="1604.37"
          cy="616.018"
          r="8.1222"
          fill="url(#paint31_radial_2143_3505)"
        />
        <circle
          opacity="0.9"
          cx="1604.37"
          cy="616.018"
          r="6.72982"
          fill="url(#paint32_radial_2143_3505)"
        />
        <path
        className="twinkling"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1605.94 615.448L1603.94 596.293L1602.3 615.448L1580 616.396L1602.45 617.541L1603.89 639.293L1605.69 617.541L1625.14 616.895L1605.94 615.448Z"
          fill="url(#paint33_radial_2143_3505)"
        />
        <circle
          cx="1604.37"
          cy="616.018"
          r="3.94507"
          fill="url(#paint34_radial_2143_3505)"
        />
        <circle
          cx="1824.41"
          cy="918.423"
          r="18.3889"
          fill="url(#paint35_radial_2143_3505)"
          fillOpacity="0.6"
        />
        <circle
          opacity="0.9"
          cx="1824.4"
          cy="918.423"
          r="13.1349"
          fill="url(#paint36_radial_2143_3505)"
        />
        <circle
          opacity="0.9"
          cx="1824.4"
          cy="918.423"
          r="10.8832"
          fill="url(#paint37_radial_2143_3505)"
        />
        <path
        className="twinkling"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1826.95 917.501L1823.72 886.524L1821.06 917.501L1785 919.035L1821.3 920.886L1823.64 956.062L1826.54 920.886L1858 919.84L1826.95 917.501Z"
          fill="url(#paint38_radial_2143_3505)"
        />
        <circle
          cx="1824.4"
          cy="918.423"
          r="6.37983"
          fill="url(#paint39_radial_2143_3505)"
        />
        <defs>
          <radialGradient
            id="paint0_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(38.8999 166.783) rotate(90) scale(18.1533)"
          >
            <stop stopColor="#277797" />
            <stop offset="1" stopColor="#277797" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint1_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(38.9003 166.783) rotate(90) scale(12.9667)"
          >
            <stop stopColor="#584CEA" />
            <stop offset="0.682292" stopColor="#594FEB" stopOpacity="0.63" />
            <stop offset="1" stopColor="#5950EC" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint2_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(38.9001 166.783) rotate(90) scale(10.7438)"
          >
            <stop stopColor="#4740E8" />
            <stop offset="0.682292" stopColor="#594FEB" stopOpacity="0.63" />
            <stop offset="1" stopColor="#5950EC" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint3_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(36.0282 169.612) rotate(180) scale(35.1907 35.1907)"
          >
            <stop offset="0.59" stopColor="#B0A2FF" />
            <stop offset="0.63" stopColor="#8C81CB" />
            <stop offset="0.69" stopColor="#675F95" />
            <stop offset="0.74" stopColor="#474268" />
            <stop offset="0.79" stopColor="#2D2A42" />
            <stop offset="0.85" stopColor="#191725" />
            <stop offset="0.9" stopColor="#0B0A10" />
            <stop offset="0.95" stopColor="#020204" />
            <stop offset="1" />
          </radialGradient>
          <radialGradient
            id="paint4_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(38.8997 166.783) rotate(90) scale(6.2981)"
          >
            <stop stopColor="white" />
            <stop offset="0.276042" stopColor="white" />
            <stop offset="0.635417" stopColor="#FFD5FB" />
            <stop offset="1" stopColor="#FFD7FB" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint5_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(55.723 907.688) rotate(90) scale(20.4041)"
          >
            <stop stopColor="#277797" />
            <stop offset="1" stopColor="#277797" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint6_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(55.7228 907.688) rotate(90) scale(14.5744)"
          >
            <stop stopColor="#584CEA" />
            <stop offset="0.682292" stopColor="#594FEB" stopOpacity="0.63" />
            <stop offset="1" stopColor="#5950EC" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint7_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(55.7234 907.688) rotate(90) scale(12.0759)"
          >
            <stop stopColor="#4740E8" />
            <stop offset="0.682292" stopColor="#594FEB" stopOpacity="0.63" />
            <stop offset="1" stopColor="#5950EC" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint8_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(52.4952 910.867) rotate(180) scale(39.5539 39.554)"
          >
            <stop offset="0.59" stopColor="#B0A2FF" />
            <stop offset="0.63" stopColor="#8C81CB" />
            <stop offset="0.69" stopColor="#675F95" />
            <stop offset="0.74" stopColor="#474268" />
            <stop offset="0.79" stopColor="#2D2A42" />
            <stop offset="0.85" stopColor="#191725" />
            <stop offset="0.9" stopColor="#0B0A10" />
            <stop offset="0.95" stopColor="#020204" />
            <stop offset="1" />
          </radialGradient>
          <radialGradient
            id="paint9_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(55.723 907.688) rotate(90) scale(7.07899)"
          >
            <stop stopColor="white" />
            <stop offset="0.276042" stopColor="white" />
            <stop offset="0.635417" stopColor="#FFD5FB" />
            <stop offset="1" stopColor="#FFD7FB" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint10_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(577.63 580.688) rotate(90) scale(13.4866)"
          >
            <stop stopColor="#277797" />
            <stop offset="1" stopColor="#277797" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint11_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(577.63 580.688) rotate(90) scale(9.63331)"
          >
            <stop stopColor="#584CEA" />
            <stop offset="0.682292" stopColor="#594FEB" stopOpacity="0.63" />
            <stop offset="1" stopColor="#5950EC" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint12_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(577.63 580.688) rotate(90) scale(7.98188)"
          >
            <stop stopColor="#4740E8" />
            <stop offset="0.682292" stopColor="#594FEB" stopOpacity="0.63" />
            <stop offset="1" stopColor="#5950EC" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint13_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(575.497 582.79) rotate(180) scale(26.1442 26.1442)"
          >
            <stop offset="0.59" stopColor="#B0A2FF" />
            <stop offset="0.63" stopColor="#8C81CB" />
            <stop offset="0.69" stopColor="#675F95" />
            <stop offset="0.74" stopColor="#474268" />
            <stop offset="0.79" stopColor="#2D2A42" />
            <stop offset="0.85" stopColor="#191725" />
            <stop offset="0.9" stopColor="#0B0A10" />
            <stop offset="0.95" stopColor="#020204" />
            <stop offset="1" />
          </radialGradient>
          <radialGradient
            id="paint14_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(577.63 580.688) rotate(90) scale(4.67904)"
          >
            <stop stopColor="white" />
            <stop offset="0.276042" stopColor="white" />
            <stop offset="0.635417" stopColor="#FFD5FB" />
            <stop offset="1" stopColor="#FFD7FB" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint15_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1419.4 541.569) rotate(90) scale(17.4533)"
          >
            <stop stopColor="#277797" />
            <stop offset="1" stopColor="#277797" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint16_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1419.4 541.569) rotate(90) scale(12.4666)"
          >
            <stop stopColor="#584CEA" />
            <stop offset="0.682292" stopColor="#594FEB" stopOpacity="0.63" />
            <stop offset="1" stopColor="#5950EC" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint17_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1419.4 541.569) rotate(90) scale(10.3295)"
          >
            <stop stopColor="#4740E8" />
            <stop offset="0.682292" stopColor="#594FEB" stopOpacity="0.63" />
            <stop offset="1" stopColor="#5950EC" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint18_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1416.64 544.289) rotate(180) scale(33.8336 33.8336)"
          >
            <stop offset="0.59" stopColor="#B0A2FF" />
            <stop offset="0.63" stopColor="#8C81CB" />
            <stop offset="0.69" stopColor="#675F95" />
            <stop offset="0.74" stopColor="#474268" />
            <stop offset="0.79" stopColor="#2D2A42" />
            <stop offset="0.85" stopColor="#191725" />
            <stop offset="0.9" stopColor="#0B0A10" />
            <stop offset="0.95" stopColor="#020204" />
            <stop offset="1" />
          </radialGradient>
          <radialGradient
            id="paint19_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1419.4 541.569) rotate(90) scale(6.05522)"
          >
            <stop stopColor="white" />
            <stop offset="0.276042" stopColor="white" />
            <stop offset="0.635417" stopColor="#FFD5FB" />
            <stop offset="1" stopColor="#FFD7FB" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint20_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1666.87 31.4622) rotate(90) scale(18.137)"
          >
            <stop stopColor="#277797" />
            <stop offset="1" stopColor="#277797" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint21_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1666.86 31.4622) rotate(90) scale(12.955)"
          >
            <stop stopColor="#584CEA" />
            <stop offset="0.682292" stopColor="#594FEB" stopOpacity="0.63" />
            <stop offset="1" stopColor="#5950EC" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint22_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1666.87 31.4622) rotate(90) scale(10.7342)"
          >
            <stop stopColor="#4740E8" />
            <stop offset="0.682292" stopColor="#594FEB" stopOpacity="0.63" />
            <stop offset="1" stopColor="#5950EC" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint23_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1664 34.2885) rotate(180) scale(35.1591 35.1591)"
          >
            <stop offset="0.59" stopColor="#B0A2FF" />
            <stop offset="0.63" stopColor="#8C81CB" />
            <stop offset="0.69" stopColor="#675F95" />
            <stop offset="0.74" stopColor="#474268" />
            <stop offset="0.79" stopColor="#2D2A42" />
            <stop offset="0.85" stopColor="#191725" />
            <stop offset="0.9" stopColor="#0B0A10" />
            <stop offset="0.95" stopColor="#020204" />
            <stop offset="1" />
          </radialGradient>
          <radialGradient
            id="paint24_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1666.87 31.4621) rotate(90) scale(6.29243)"
          >
            <stop stopColor="white" />
            <stop offset="0.276042" stopColor="white" />
            <stop offset="0.635417" stopColor="#FFD5FB" />
            <stop offset="1" stopColor="#FFD7FB" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint25_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1786.37 179.018) rotate(90) scale(11.3711)"
          >
            <stop stopColor="#277797" />
            <stop offset="1" stopColor="#277797" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint26_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1786.37 179.018) rotate(90) scale(8.1222)"
          >
            <stop stopColor="#584CEA" />
            <stop offset="0.682292" stopColor="#594FEB" stopOpacity="0.63" />
            <stop offset="1" stopColor="#5950EC" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint27_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1786.37 179.018) rotate(90) scale(6.72982)"
          >
            <stop stopColor="#4740E8" />
            <stop offset="0.682292" stopColor="#594FEB" stopOpacity="0.63" />
            <stop offset="1" stopColor="#5950EC" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint28_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1784.57 180.79) rotate(180) scale(22.0431 22.0431)"
          >
            <stop offset="0.59" stopColor="#B0A2FF" />
            <stop offset="0.63" stopColor="#8C81CB" />
            <stop offset="0.69" stopColor="#675F95" />
            <stop offset="0.74" stopColor="#474268" />
            <stop offset="0.79" stopColor="#2D2A42" />
            <stop offset="0.85" stopColor="#191725" />
            <stop offset="0.9" stopColor="#0B0A10" />
            <stop offset="0.95" stopColor="#020204" />
            <stop offset="1" />
          </radialGradient>
          <radialGradient
            id="paint29_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1786.37 179.018) rotate(90) scale(3.94507)"
          >
            <stop stopColor="white" />
            <stop offset="0.276042" stopColor="white" />
            <stop offset="0.635417" stopColor="#FFD5FB" />
            <stop offset="1" stopColor="#FFD7FB" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint30_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1604.37 616.018) rotate(90) scale(11.3711)"
          >
            <stop stopColor="#277797" />
            <stop offset="1" stopColor="#277797" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint31_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1604.37 616.018) rotate(90) scale(8.1222)"
          >
            <stop stopColor="#584CEA" />
            <stop offset="0.682292" stopColor="#594FEB" stopOpacity="0.63" />
            <stop offset="1" stopColor="#5950EC" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint32_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1604.37 616.018) rotate(90) scale(6.72982)"
          >
            <stop stopColor="#4740E8" />
            <stop offset="0.682292" stopColor="#594FEB" stopOpacity="0.63" />
            <stop offset="1" stopColor="#5950EC" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint33_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1602.57 617.79) rotate(180) scale(22.0431 22.0431)"
          >
            <stop offset="0.59" stopColor="#B0A2FF" />
            <stop offset="0.63" stopColor="#8C81CB" />
            <stop offset="0.69" stopColor="#675F95" />
            <stop offset="0.74" stopColor="#474268" />
            <stop offset="0.79" stopColor="#2D2A42" />
            <stop offset="0.85" stopColor="#191725" />
            <stop offset="0.9" stopColor="#0B0A10" />
            <stop offset="0.95" stopColor="#020204" />
            <stop offset="1" />
          </radialGradient>
          <radialGradient
            id="paint34_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1604.37 616.018) rotate(90) scale(3.94507)"
          >
            <stop stopColor="white" />
            <stop offset="0.276042" stopColor="white" />
            <stop offset="0.635417" stopColor="#FFD5FB" />
            <stop offset="1" stopColor="#FFD7FB" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint35_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1824.41 918.423) rotate(90) scale(18.3889)"
          >
            <stop stopColor="#277797" />
            <stop offset="1" stopColor="#277797" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint36_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1824.4 918.423) rotate(90) scale(13.1349)"
          >
            <stop stopColor="#584CEA" />
            <stop offset="0.682292" stopColor="#594FEB" stopOpacity="0.63" />
            <stop offset="1" stopColor="#5950EC" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint37_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1824.4 918.423) rotate(90) scale(10.8832)"
          >
            <stop stopColor="#4740E8" />
            <stop offset="0.682292" stopColor="#594FEB" stopOpacity="0.63" />
            <stop offset="1" stopColor="#5950EC" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint38_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1821.5 921.288) rotate(180) scale(35.6474 35.6474)"
          >
            <stop offset="0.59" stopColor="#B0A2FF" />
            <stop offset="0.63" stopColor="#8C81CB" />
            <stop offset="0.69" stopColor="#675F95" />
            <stop offset="0.74" stopColor="#474268" />
            <stop offset="0.79" stopColor="#2D2A42" />
            <stop offset="0.85" stopColor="#191725" />
            <stop offset="0.9" stopColor="#0B0A10" />
            <stop offset="0.95" stopColor="#020204" />
            <stop offset="1" />
          </radialGradient>
          <radialGradient
            id="paint39_radial_2143_3505"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1824.4 918.423) rotate(90) scale(6.37983)"
          >
            <stop stopColor="white" />
            <stop offset="0.276042" stopColor="white" />
            <stop offset="0.635417" stopColor="#FFD5FB" />
            <stop offset="1" stopColor="#FFD7FB" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
  );
};

export default TwinklingStar;
