import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { history } from "../managers/history";
import { socialUrls, urls } from "../constants";
import CheckBackSoonPopup from "./checkBackSoonPopup";

const Sidebar = (props) => {
  const { t } = useTranslation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleClick = () => {
    props.setOpenSidebar(!props.openSidebar);
    history.push("/");
  };
  const Navigate = (link) => {
    window.open(link);
  };

  const WhitePaper = () => {
    setIsPopupOpen(true);
    document.body.classList.add("popup-open");
  };
  const closePopup = () => {
    setIsPopupOpen(false);
    document.body.classList.remove("popup-open");
  };

  return (
    <>
      <CheckBackSoonPopup isPopupOpen={isPopupOpen} closePopup={closePopup}/>
      <div
        className={`w-0 h-full fixed top-0 bg-blue-191727 z-3 transition-width duration-300 ease-in-out ${
          props.openSidebar ? "w-281px box" : ""
        }`}
      >
        <div className={`${props.openSidebar ? "block" : "hidden"}`}>
          <div className="w-full flex items-center justify-between px-4 mb-4 pt-5">
            <img
              src="/images/astromint-logo.svg"
              alt="logo"
              className="cursor-pointer h-7"
              loading="lazy"
              onClick={() => handleClick()}
            />
          </div>

          <div className="w-full px-4 pt-4">
            <div
              className="w-full h-10 flex items-center cursor-pointer"
              onClick={() => handleClick()}
            >
              <span className="font-SourceSansProRegular text-ft4 text-white">
                {t("astromint_fe.header.home")}
              </span>
            </div>
            <div
              className="w-full h-10 flex items-center cursor-pointer"
              onClick={() => handleClick()}
            >
              <span className="font-SourceSansProRegular text-ft4 text-white">
                {t("astromint_fe.header.docs")}
              </span>
            </div>
            <div
              className="w-full h-10 flex items-center cursor-pointer"
              onClick={WhitePaper}
            >
              <span className="font-SourceSansProRegular text-ft4 text-white">
                {t("astromint_fe.header.whitepaper")}
              </span>
            </div>
            <div
              className="w-full h-10 flex items-center cursor-pointer"
              onClick={() => Navigate(socialUrls.medium)}
            >
              <span className="font-SourceSansProRegular text-ft4 text-white">
                {t("astromint_fe.header.medium")}
              </span>
            </div>
            <div
               onClick={WhitePaper}
              className="w-full flex items-center border-opacity-50 pt-7"
            >
              <button className="text-center font-SourceSansProRegular text-ft4 text-white h-10 w-131px rounded-10 gradient-theme">
                {t("astromint_fe.header.launchApp")}
              </button>
            </div>
          </div>
          <div className="absolute bottom-0 flex justify-center w-full gap-6 pb-6">
            <img
              onClick={() => Navigate(socialUrls.twitter)}
              src="/images/twitter.svg"
              alt="twitter"
              className="h-7 w-7 cursor-pointer"
              loading="lazy"
            />
            <img
              onClick={() => Navigate(socialUrls.telegram)}
              src="/images/telegram.svg"
              alt="telegram"
              className="h-6 w-6 cursor-pointer"
              loading="lazy"
            />
            <img
              onClick={() => Navigate(socialUrls.medium)}
              src="/images/round-logo.svg"
              alt="logo"
              className="h-7 w-7 cursor-pointer"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </>
  );
};

const SidebarComponent = React.memo(Sidebar);

export default SidebarComponent;
