import React from "react";
import { useTranslation } from "react-i18next";
import ProfileComponent from "../../common/profile";
import { contributorDetails, advisorDetails } from "../../constants";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const visible = { opacity: 1, x: 0, transition: { duration: 0.5 } };

const itemVariants = {
  hidden: { opacity: 0, x: 90 },
  visible,
};

const ContributersComponent = () => {
  const { t } = useTranslation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <>
      <div className="text-white relative ">
        <img
          className="absolute tab:-left-84 desktop:-left-84 top-56.5 mobile:hidden "
          src="/images/bg-gradient-light.svg"
          alt="Light bg"
        />
        <img
          className="absolute tab:hidden desktop:hidden top-56.5 mobile:right-0 mobile:top-111 "
          src="/images/overlay.svg"
          alt="overlay bg"
        />
        <img
          className="absolute tab:hidden desktop:hidden top-56.5 mobile:left-0 mobile:top-125"
          src="/images/light.svg"
          alt="light bg"
        />
        <img
          src="/images/white-arrow.svg"
          alt="arrow"
          className="absolute top-111 left-8per mobile:hidden"
          loading="lazy"
        />
        <img
          src="/images/small-white-arrow.svg"
          alt="arrow"
          className="absolute right-12per top-84 mobile:hidden"
          loading="lazy"
        />
        <div className="relative mx-auto medium:px-52 mobile:px-10 tab:px-20 px-20 pt-16 mobile:pt-0">
          <div className="4xl:px-44">
            <div className="font-AspireSCBlackRegular text-ft45 mobile:text-ft8 tab:text-ft28 small:text-ft4 desktop:pl-12 pl-5">
              {t("astromint_fe.title.contributors")}
            </div>
            <motion.div
              ref={ref}
              variants={itemVariants}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              className="bg-darkBlue-25 w-fit backdrop-blur-4xl desktop:pl-12 desktop:pr-12 pl-5 pr-5 mobile:pr-5 desktop:pb-9 desktop:pt-7 pt-4 pb-4 rounded-7.5 mt-2 tab:mr-32 lg:mr-40 4xl:mr-0 description-bg cursor-default"
            >
              <div
                ref={ref}
                variants={itemVariants}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                className="text-ft12 font-SourceSansVariableRoman mobile:text-ft3 mobile:w-64 tab:text-ft5"
              >
                {t("astromint_fe.description.contributorDesc")}
              </div>
            </motion.div>
            <div className="mt-75px mobile:mt-8">
              <span className="text-ft24-30 font-SpaceGrotesk font-bold mobile:text-ft4">
                {t("astromint_fe.features.coreContributors")}
              </span>
              <div className="grid grid-cols-4 mt-7.5 tab:grid-cols-3 mobile:grid-cols-2 gap-5">
                {contributorDetails?.map((item, index) => (
                  <ProfileComponent
                    key={index}
                    imgSrc={item.imgSrc}
                    contributorName={item.contributorName}
                    role={item.role}
                    linkedIn={item.linkedIn}
                    type="linkedin"
                  />
                ))}
              </div>
            </div>
            <div className="mt-85px relative pb-28 mobile:mt-12">
              <img
                className="absolute right-20per mobile:h-4 mobile:w-4 mobile:-top-32"
                src="/images/arrow-border-white.svg"
                alt="Arrow"
                loading="lazy"
              />
              <span className="text-ft24-30 font-SpaceGrotesk font-bold mobile:text-ft4">
                {t("astromint_fe.features.strategicAadvisors")}
              </span>
              <div className="grid grid-cols-4 mt-7.5 tab:grid-cols-3 mobile:grid-cols-2 gap-5">
                {advisorDetails?.map((item, index) => (
                  <ProfileComponent
                    key={index}
                    imgSrc={item.imgSrc}
                    advisorName={item.advisorName}
                    role={item.role}
                    linkedIn={item.linkedIn}
                    type="linkedin"
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContributersComponent;
