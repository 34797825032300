import React from "react";
import { useTranslation } from "react-i18next";
import { history } from "../managers/history";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div>
      <hr className="gradient-hr medium:mx-32 mx-7 mobile:mx-5 mt-2" />
      <div className="text-white relative z-1 text-ft12 medium:px-52 mobile:px-10 px-20 tab:px-20 pb-10">
        <div className="4xl:px-44 pb-9 mobile:pb-4 pt-16 mobile:pt-7 flex justify-between">
          <div
            className="text-ft8 font-SourceSansProRegular mobile:text-ft11 cursor-pointer"
            onClick={() => history.push("/terms-of-use")}
          >
            {t("astromint_fe.features.termsOfUse")}
          </div>
          <div className="text-ft8 font-SourceSansProRegular mobile:text-ft11 tab:pl-20 mobile:pr-6">
            {t("astromint_fe.features.mediaKit")}
          </div>
          <div
            className="text-ft8 font-SourceSansProRegular mobile:text-ft11 cursor-pointer w-max"
            onClick={() => history.push("/privacy-policy")}
          >
            {t("astromint_fe.features.privacyPolicy")}
          </div>
        </div>
        <div className="flex justify-center">
          <div
            className="4xl:mx-44 text-ft10 font-SourceSansVariableRoman mobile:text-ft11 mt-10 text-center mobile:max-w-71 cursor-pointer"
            onClick={() => history.push("/privacy-policy")}
          >
            {t("astromint_fe.description.copyright")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
