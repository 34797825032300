import React from "react";
import { createRoot } from "react-dom/client";
import Routes from "./routes";
import store from "./store.js";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import "./assets/styles/custom.css";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
  resources: {
    en: { translation: require("./locales/en.json") },
  },
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

function WebApp() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <Routes component={Routes} />
      </Provider>
    </BrowserRouter>
  );
}

createRoot(document.getElementById("root")).render(
  <I18nextProvider i18n={i18n}>
    <WebApp />
  </I18nextProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
