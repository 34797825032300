import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { containerDetails } from "../../constants";
import CheckBackSoonPopup from "../../common/checkBackSoonPopup";

const visible = { opacity: 1, x: 0, transition: { duration: 0.5 } };

const itemVariants = {
  hidden: { opacity: 0, x: 90 },
  visible,
};

const HowItWorksComponent = () => {
  const { t } = useTranslation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const [bubbleRef, bubbleInView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const [isPopupOpen, setIsPopupOpen] = useState(false);

const WhitePaper =()=>{
  setIsPopupOpen(true);
  document.body.classList.add('popup-open');
}
const closePopup=()=> {
  setIsPopupOpen(false);
  document.body.classList.remove('popup-open');
}

  return (
    <>
      <CheckBackSoonPopup isPopupOpen={isPopupOpen} closePopup={closePopup}/>
      <div className="text-white relative z-1 text-ft12 medium:px-52 mobile:px-10 px-20 pt-23 tab:px-20 pb-20 popup-open">
        <div className="4xl:px-44 pb-20 mobile:pb-7">
          <img
            src="/images/white-arrow.svg"
            alt="arrow"
            className="pl-10 pb-12 mobile:hidden"
            loading="lazy"
          />
          <div className="flex justify-end">
            <img
              src="/images/small-white-arrow.svg"
              alt="arrow"
              className="pl-10 pt-5 pb-5 tab:hidden desktop:hidden"
              loading="lazy"
            />
          </div>
          <div className="font-AspireSCBlackRegular text-ft45 mobile:text-ft8 tab:text-ft28 small:text-ft4 desktop:pl-12 pl-5">
            {t("astromint_fe.title.howItWorks")}
          </div>
          <motion.div
            ref={ref}
            variants={itemVariants}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            className="bg-darkBlue-25 w-fit backdrop-blur-4xl desktop:pl-12 desktop:pr-12 pl-5 pr-5 mobile:pr-5 desktop:pb-9 desktop:pt-7 pt-4 pb-4 rounded-7.5 mt-2 description-bg cursor-default"
          >
            <div className="desktop:flex items-center gap-23">
              <div className="text-ft12 font-SourceSansVariableRoman mobile:text-ft3 tab:text-ft5">
                {t("astromint_fe.description.workingDescription")}
              </div>
              <div onClick={WhitePaper} className="w-65 tab:mt-10 mobile:mt-7">
                <button className="font-SourceSansProSemiBold text-ft8 mobile:text-ft3 text-white h-67px w-65 mobile:w-48.5 mobile:h-11 rounded-10 gradient-theme flex justify-center items-center gap-2">
                  <img src="/images/paper.svg" alt="paper" loading="lazy" />
                  {t("astromint_fe.features.checkWhitepaper")}
                </button>
              </div>
            </div>
          </motion.div>
          <div className="max-w-185 w-full mt-2 desktop:pl-12 pl-5">
            <div
              className="flex flex-wrap mt-9 gap-2 mobile:mt-21px"
              ref={bubbleRef}
            >
              {containerDetails?.map((item, index) => (
                <SmallContainer
                  item={item}
                  t={t}
                  key={index}
                  bubbleInView={bubbleInView}
                />
              ))}
            </div>
          </div>
          <img
            src="/images/tiny-arrow.svg"
            alt="arrow"
            className="absolute right-20"
            loading="lazy"
          />
        </div>
        <div className="flex justify-center relative mt-6">
          <img
            src="/images/basic-model.svg"
            alt="model"
            className="mobile:hidden"
            loading="lazy"
          />
          <img
            src="/images/basic-model-mobile.svg"
            alt="model"
            className="tab:hidden desktop:hidden"
            loading="lazy"
          />
          <img
            src="/images/mini-arrow.svg"
            alt="arrow"
            className="absolute left-32 top-4 mobile:hidden"
            loading="lazy"
          />

          <img
            src="/images/tiny-white-arrow.svg"
            alt="arrow"
            className="absolute left-10 top-4 tab:hidden desktop:hidden"
            loading="lazy"
          />
        </div>
      </div>
    </>
  );
};

const SmallContainer = ({ item, t, bubbleInView }) => {
  return (
    <motion.div
      variants={itemVariants}
      initial="hidden"
      animate={bubbleInView ? "visible" : "hidden"}
      className="features-bg cursor-pointer px-25px desktop:py-19px flex w-fit rounded-17.5 mobile:py-13px mobile:px-4 tab:py-13px backdrop-blur-4xl border-1 border-gray-2E3945"
    >
      <img
        className="mr-1.5 mobile:w-13px"
        src={item.imgSrc}
        alt={t(item.alt)}
        loading="lazy"
      />
      <span className="font-SpaceGrotesk text-ft8 mobile:text-ft14-13d6">
        {t(item.title)}
      </span>
    </motion.div>
  );
};

export default HowItWorksComponent;
