import React from "react";
import { useTranslation } from "react-i18next";
import {
  trustedSupporters,
  partners,
  primePartners,
} from "../../constants";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const visible = { opacity: 1, x: 0, transition: { duration: 0.5 } };

const itemVariants = {
  hidden: { opacity: 0, x: 90 },
  visible,
};

const PartnershipsComponent = () => {
  const { t } = useTranslation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <>
      <div className="text-white relative z-1 text-ft12 medium:px-52 mobile:px-10 px-20 tab:px-20 mt-20 mobile:mt-0 pb-20 mobile:pb-9">
        <div className="4xl:px-44 pb-20 mobile:pb-7 pt-80 mobile:pt-32">
          <img
            className="absolute left-10 mobile:h-3 mobile:w-3 -top-10 tab:hidden desktop:hidden"
            src="/images/arrow-border-white.svg"
            alt="Arrow"
            loading="lazy"
          />
          <img
            src="/images/arrow-border-white.svg"
            alt="arrow"
            className="pl-10 pb-12 absolute -top-20 left-20 mobile:hidden"
            loading="lazy"
          />
          <div className="font-AspireSCBlackRegular text-ft45 mobile:text-ft8 relative tab:text-ft28 small:text-ft4 desktop:pl-12 pl-5">
            <img
              className="absolute right-0 mobile:h-5 mobile:w-5 -top-10 tab:hidden desktop:hidden"
              src="/images/arrow-border-white.svg"
              alt="Arrow"
              loading="lazy"
            />
            {t("astromint_fe.title.partnerships")}
          </div>
          <motion.div
            ref={ref}
            variants={itemVariants}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            className="bg-darkBlue-25 w-fit backdrop-blur-4xl desktop:pl-12 desktop:pr-12 pl-5 pr-5 mobile:pr-5 desktop:pb-9 desktop:pt-7 pt-4 pb-4 rounded-7.5 mt-2 lg:mr-40 4xl:mr-0 description-bg cursor-default"
          >
            <div className="text-ft12 font-SourceSansVariableRoman mobile:text-ft3 tab:text-ft5">
              {t("astromint_fe.description.partnershipDescription")}
            </div>
          </motion.div>
          <div>
            <div className="font-SpaceGrotesk text-gray-4A5868 pt-24 mobile:text-ft4 mobile:pt-10 font-medium">
              {t("astromint_fe.features.trustedSupporters")}
            </div>
            <div className="desktop:flex desktop:gap-14 grid grid-cols-5 gap-10 justify-start items-center mt-11 mobile:mt-5 tab:grid-cols-3 mobile:grid-cols-2 mobile:gap-14 relative">
              {trustedSupporters?.map((item, index) => (
                <a
                  href={item.link}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="flex justify-center desktop:items-center"
                  key={index}
                >
                  <img src={item.imgSrc} alt="brand" loading="lazy" />
                </a>
              ))}
              <img
                className="absolute -right-40 w-7 h-7 -bottom-10 mobile:hidden"
                src="/images/white-arrow.svg"
                alt="Arrow"
                loading="lazy"
              />
            </div>
            <div className="font-SpaceGrotesk text-gray-4A5868 pt-24 mobile:text-ft4 mobile:pt-14 font-medium">
              {t("astromint_fe.features.partners")}
            </div>
            <div className="desktop:flex desktop:gap-16 grid grid-cols-5 gap-10 justify-start items-center mt-11 mobile:mt-5 tab:grid-cols-3 mobile:grid-cols-2 mobile:gap-14 relative">
              {primePartners?.map((item, index) => (
                <a
                  href={item.link}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="flex justify-center desktop:items-center tab:hidden mobile:hidden"
                  key={index}
                >
                  <img src={item.imgSrc} alt="brand" loading="lazy" />
                </a>
              ))}
              {partners?.map((item, index) => (
                <a
                  href={item.link}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="flex justify-center desktop:items-center desktop:hidden"
                  key={index}
                >
                  <img src={item.imgSrc} alt="brand" loading="lazy" />
                </a>
              ))}
              <img
                className="absolute right-0 mobile:h-5 mobile:w-5 -bottom-7 tab:hidden desktop:hidden"
                src="/images/arrow-grey-border.svg"
                alt="Arrow"
                loading="lazy"
              />
              <img
                className="absolute -left-40 h-5 w-5 -bottom-24 mobile:hidden"
                src="/images/arrow-border-white.svg"
                alt="Arrow"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnershipsComponent;
