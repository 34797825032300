import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { history } from "../managers/history";
import Hamburger from "hamburger-react";
import { socialUrls } from "../constants";
import CheckBackSoonPopup from "./checkBackSoonPopup";

const Header = (props) => {
  const { t } = useTranslation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const Navigate = (link) => {
    window.open(link);
  };
  const WhitePaper = () => {
    setIsPopupOpen(true);
    document.body.classList.add("popup-open");
  };
  const closePopup = () => {
    setIsPopupOpen(false);
    document.body.classList.remove("popup-open");
  };

  return (
    <>
       <CheckBackSoonPopup isPopupOpen={isPopupOpen} closePopup={closePopup}/>
      <header
        className={`w-full h-20.5 top-0 sticky z-2 mobile:h-17.5 ${
          props.header === "blur" || props.openSidebar ? "header-gradient" : ""
        }`}
      >
        <div className="flex items-center justify-between h-full px-10 mobile:px-4">
          <div
            className="flex items-center cursor-pointer"
            onClick={() => history.push("/")}
          >
            <img
              src="/images/astromint-logo.svg"
              alt="logo"
              loading="lazy"
              className="cursor-pointer tab:h-7 mobile:h-7"
            />
          </div>
          <div className="flex flex-row items-center justify-center mr-2 cursor-pointer gap-11 tab:hidden mobile:hidden">
            <span className="w-fit text-center font-SourceSansProRegular text-ft8 text-white">
              {t("astromint_fe.header.docs")}
            </span>
            <span
              onClick={WhitePaper}
              className="w-fit text-center font-SourceSansProRegular text-ft8 text-white"
            >
              {t("astromint_fe.header.whitepaper")}
            </span>
            <span
              onClick={() => Navigate(socialUrls.medium)}
              className="w-fit text-center font-SourceSansProRegular text-ft8 text-white"
            >
              {t("astromint_fe.header.medium")}
            </span>
            <button
               onClick={WhitePaper}
              className="text-center font-SourceSansProRegular text-ft20 text-white h-10 w-131px rounded-10 gradient-theme"
            >
              {t("astromint_fe.header.launchApp")}
            </button>
          </div>
          <div className="flex items-center desktop:hidden">
            <Hamburger
              direction="right"
              color="white"
              size={21}
              toggled={props.openSidebar}
              toggle={() => props.setOpenSidebar(!props.openSidebar)}
            />
          </div>
        </div>
      </header>
    </>
  );
};

const HeaderComponent = React.memo(Header);

export default HeaderComponent;
