import React, { useState, useEffect } from "react";
import HeaderComponent from "../../common/header";
import TermsOfUseComponent from "./termsOfUse";

function Terms() {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [header, setHeader] = useState("transparent");

  const listenScrollEvent = () => {
    if (window.scrollY < 33) {
      return setHeader("transparent");
    } else if (window.scrollY > 30) {
      return setHeader("blur");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", listenScrollEvent);
    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  return (
    <div className="bg-blue-0F161E">
      <HeaderComponent
        openSidebar={openSidebar}
        setOpenSidebar={setOpenSidebar}
        header={header}
      />
      <TermsOfUseComponent
        openSidebar={openSidebar}
        setOpenSidebar={setOpenSidebar}
      />
    </div>
  );
}

export default Terms;
